/**
 * Zatvorenie všetkých zoznamov custom selectu.
 */
const hideAllOptionsLists = () => {
  document
    .querySelectorAll(".select-items")
    .forEach((item) => item.classList.add("select-hide"))
}

/**
 * Inicializácia všetkých custom selectov.
 */
export const initializeCustomSelects = () => {
  const customSelects = document.querySelectorAll(".custom-select")

  customSelects.forEach((customSelect) => {
    const nativeSelect = customSelect.querySelector("select")

    const renderCustomSelect = () => {
      // vytvorenie elementu, ktorý zobrazuje zvolenú možnosť
      const display = document.createElement("div")
      display.classList.add("select-selected")
      display.textContent =
        nativeSelect.options[nativeSelect.selectedIndex].text

      customSelect.appendChild(display)

      // vytvorenie elementu pre zobrazenie zoznamu možností
      const optionsList = document.createElement("div")
      optionsList.classList.add("select-items", "select-hide")

      customSelect.appendChild(optionsList)

      /**
       * Klik na element zobrazujúci zvolenú možnosť.
       */
      display.addEventListener("click", () => {
        hideAllOptionsLists()
        // zobrazí príslušný zoznam možností
        optionsList.classList.remove("select-hide")
      })

      Array.from(nativeSelect.options).forEach((nativeOption) => {
        // pridanie možností do zoznamu, okrem práve zvolenej možnosti
        if (nativeSelect.selectedIndex !== nativeOption.index) {
          // vytvorenie elementu možnosti
          const optionDisplay = document.createElement("div")
          optionDisplay.textContent = nativeOption.text

          optionsList.appendChild(optionDisplay)

          // klik na možnosť v zozname
          optionDisplay.addEventListener("click", () => {
            // nastaví sa hodnota do natívneho selectu
            nativeSelect.value = nativeOption.value
            nativeSelect.dispatchEvent(new Event("change"))

            // znovu sa vygeneruje celý custom select
            renderCustomSelect()

            // odstráni sa starý render
            display.remove()
            optionsList.classList.toggle("select-hide")
            optionsList.addEventListener("transitionend", () =>
              // starý zoznam sa odstráni až po ukončení animácie
              optionsList.remove()
            )
          })
        }
      })
    }

    // prvotný render
    renderCustomSelect()
  })

  /**
   * Zatvorenie všetkých otvorených zoznamov po kliku mimo zoznamu.
   */
  document.addEventListener("click", ({ target }) => {
    if (
      target &&
      target instanceof Node &&
      Array.from(customSelects)
        .map((customSelect) => customSelect.contains(target))
        .every((contains) => contains === false)
      // cieľ kliku nie je obsiahnutý v žiadnych zoznamoch
    ) {
      hideAllOptionsLists()
    }
  })
}
