export const initializeLanguageSelect = () => {
  /**
   * Pri zmene jazyka sa odošle formulár.
   */
  document.querySelectorAll(".language-select-form").forEach((form) => {
    form.querySelector("select").addEventListener("change", () => form.submit())
  })

  // prepínač na desktope
  const languageSelectDesktop = document.querySelector(
    ".custom-select.language-select-desktop"
  )

  /**
   * Otvorenie pri prechode kurzorom.
   */
  languageSelectDesktop.addEventListener("mouseover", () =>
    languageSelectDesktop
      .querySelector(".select-items")
      .classList.remove("select-hide")
  )

  /**
   * Zatvorenie pri odchode s kurzorom.
   */
  languageSelectDesktop.addEventListener("mouseleave", () =>
    languageSelectDesktop
      .querySelector(".select-items")
      .classList.add("select-hide")
  )
}
