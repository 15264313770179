import "./base.scss"

import "ekko-lightbox"
import "ekko-lightbox/dist/ekko-lightbox.css"

import { initializeCustomSelects } from "./components/custom-select"
import { initializeLanguageSelect } from "./components/language-select"

const onReady = (fn) => {
  if (document.readyState !== "loading") {
    fn()
  } else {
    document.addEventListener("DOMContentLoaded", fn)
  }
}

onReady(() => {
  initializeCustomSelects()
  initializeLanguageSelect()
})
